import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import VideoEmbed from '../components/VideoEmbed'

const FeaturedFilmTemplate = ({
  content,
  contentComponent,
  title,
  venue,
  video,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <VideoEmbed url={video} />
      <strong>{title}</strong> - <span>{venue}</span>
      <PageContent className="content" content={content} />
    </div>
  )
}

FeaturedFilmTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  venue: PropTypes.string,
  video: PropTypes.string,
}

const FeaturedFilm = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FeaturedFilmTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        venue={post.frontmatter.venue}
        video={post.frontmatter.video}
      />
    </Layout>
  )
}

FeaturedFilm.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FeaturedFilm

export const filmsPageQuery = graphql`
  query FeaturedFilm($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        venue
        video
      }
    }
  }
`
